.error {
  @apply bg-red-200;
}

.ok {
  @apply bg-green-200;
}

.info {
  @apply bg-gray-200;
}

.warning {
  @apply bg-yellow-200;
}

.container {
  position: fixed;
  right: 0;
  top: 7vw;
}

// see https://stackoverflow.com/a/25776315
.pos {
  position: absolute;
  left: 0%; /* position the left edge of the element at the middle of the parent */
  top: 0px;
  transform: translate(-50%, -50%);
}

.dismissAllButton {
  height: 45px;

  position: fixed;
  bottom: 5vw; /* position the top  edge of the element at the middle of the parent */
  left: 30vw; /* position the left edge of the element at the middle of the parent */

  transform: translate(
    -50%,
    -50%
  ); /* This is a shorthand of translateX(-50%) and translateY(-50%) */
}
