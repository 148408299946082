@use 'constant.module.scss' as const;

.container {
  font-size: 1.1em;
  border: 1px solid const.$border-gray;
  background: const.$white;

  padding: 0px 12px 0px 0px;
  margin-right: 10px;
  border-radius: 24px;
}

.count {
  line-height: 2.1em;
  font-size: 0.8em;
  margin: 0 10px;
}
