$gray: #f8f9fa;
$white: white;
$dark-gray: #e0e0e0;
$darker-gray: gray;
$green: green;
$red: red;

$padding: 15px;
$border-radius: 12px;

$border-gray: $dark-gray;
$bg-gray: $gray;

@mixin contenteditable {
  // padding: $padding / 2;
  // border-radius: $border-radius;
  // border: solid 1px $darker-gray;
  // display: inline-block;
  // box-sizing: border-box;
  // max-width: 100%;
  // width: 100%;
  // resize: vertical;
}

@mixin hide-scroll-bar() {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin border($top-padding: $padding/3, $side-padding: $padding/3) {
  border-radius: 12px;
  border: 1px solid $border-gray;
  padding: $top-padding $side-padding;
}

@mixin button {
  outline: none;
  user-select: none;
  cursor: pointer;

  // TODO:
  &:hover {
    box-shadow: 0 3px $bg-gray;
  }
  &:active {
    box-shadow: 0 3px $bg-gray;
    transform: translateY(2px);
  }
}

.j-contenteditable {
  @apply p-2 rounded-sm border border-gray-400 inline-block box-border w-full resize-y;
}

.btn-action {
  @apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

.btn-create {
  @apply py-2 px-1 sm:py-3 md:px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

// TODO: should we use a prefix?
.j-btn-create {
  @apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

// TODO: should we use a prefix?
.j-btn-cancel {
  @apply py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75 cursor-pointer;
}
