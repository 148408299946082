@use 'constant.module.scss' as const;

.container {
  min-width: 45vw;

  // label {
  //   margin: const.$padding/3;
  // }
  // input {
  //   width: 70%;
  //   border: none;
  //   border-bottom: 1px solid const.$darker-gray;
  // }

  div[contentEditable="true"] {
    @include const.contenteditable();
    min-height: 120px;
  }
}

.personaPrompt {
  button {
    margin: 0 const.$padding/2;
  }
}

.editorInfo {
  margin-right: 20px;
  color: const.$darker-gray;
}

.category {
}

.line {
  &:not(:first-child) {
    margin-bottom: const.$padding * 2;
  }
}

.threadLine {
  @apply flex ml-5 bg-gray-300 w-1 h-12;
}
