.modal {
  @apply z-20 fixed;

  left: 0;
  top: 0;

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2); /* Black w/ opacity */
  overflow: hidden;
}

.childrenContainer {
  @apply z-30;
  height: 100%;
}
