@use 'constant.module.scss' as const;

$size: 60px;

.checkoutBtn {
  @include const.button();

  height: $size;
  width: $size;
  line-height: $size;

  position: fixed;
  bottom: 10vw; /* position the top  edge of the element at the middle of the parent */
  right: 10vw; /* position the left edge of the element at the middle of the parent */

  font-weight: 400;
  text-align: center;
  font-size: 0.7em;
}

.checkoutModal {
  position: fixed;
  width: 300px;
  height: 430px;
  padding: 25px;
  border: 0.5px solid #e1e1e1;
  border-radius: 16px;
  background: white;
}

.summary {
  overflow-y: auto;
  height: 164px;
  margin-right: -15px;
  padding-right: 15px;
}
.summary:after {
  content: "";
  display: block;
  position: absolute;
  right: -2rem;
  width: 2rem;
  height: 1px;
}