@use 'constant.module.scss' as const;

.author {
  display: inline-flex;
}
.by {
  margin: 0 5px;
}

.time {
  padding: const.$padding;
}

.reaction {
  padding: 0 const.$padding;
  margin-bottom: const.$padding;
}

.footer {
  border-top: 1px solid const.$dark-gray;
  color: const.$darker-gray;
  padding: const.$padding/2 const.$padding;
}

.sentiment {
  color: green;
}

.personaContainer {
  margin-bottom: 5px;
}

.info {
  @include const.button;
  font-size: 1.2em;
}

.ellipsisTextContainer {
  p {
    @apply overflow-ellipsis overflow-hidden;
    white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // max-width: 100%;
  }
}
